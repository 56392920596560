import * as React from 'react'
import { SVGProps } from 'react'

function LogoNovoRumo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='200'
      height='44'
      viewBox='0 0 200 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.3275 10.398C19.2764 9.8986 18.2102 9.64891 17.129 9.64891C15.777 9.64891 14.5385 9.97226 13.4149 10.6165C12.2913 11.2619 11.4074 12.1508 10.762 13.2844C10.1166 14.418 9.79446 15.6614 9.79446 17.0135C9.79446 18.3655 10.1166 19.609 10.762 20.7425C11.4062 21.8761 12.2913 22.7713 13.4149 23.4267C14.5385 24.0821 15.7757 24.4092 17.129 24.4092C18.169 24.4092 19.2152 24.1808 20.2651 23.7226C21.3151 23.2656 22.2464 22.6302 23.0579 21.8187L25.5235 24.5653C24.3787 25.7301 23.0479 26.6514 21.5285 27.3268C20.0092 28.0035 18.4699 28.3406 16.9093 28.3406C14.8082 28.3406 12.8831 27.8412 11.1365 26.8424C9.38872 25.8437 8.01543 24.4866 7.01668 22.77C6.01793 21.0534 5.51855 19.1558 5.51855 17.0746C5.51855 14.9947 6.02792 13.1008 7.04789 11.3955C8.06662 9.69011 9.45613 8.34804 11.2139 7.36926C12.9717 6.39173 14.9218 5.90234 17.0654 5.90234C18.6259 5.90234 20.1553 6.21445 21.6534 6.83867C23.1515 7.46289 24.4312 8.32681 25.4923 9.42919L23.0579 12.4879C22.2876 11.5952 21.3775 10.8974 20.3275 10.398Z'
        fill='#121C25'
      />
      <path
        d='M43.608 7.37317C45.387 8.35069 46.7853 9.69277 47.8053 11.3994C48.824 13.106 49.3346 14.9986 49.3346 17.0785C49.3346 19.1597 48.824 21.0573 47.8053 22.7739C46.7853 24.4905 45.387 25.8476 43.608 26.8463C41.829 27.8451 39.8577 28.3445 37.6942 28.3445C35.5306 28.3445 33.5593 27.8451 31.7803 26.8463C30.0013 25.8476 28.6018 24.4905 27.583 22.7739C26.5631 21.0573 26.0537 19.1597 26.0537 17.0785C26.0537 14.9986 26.5631 13.1048 27.583 11.3994C28.6018 9.69402 30.0013 8.35194 31.7803 7.37317C33.5593 6.39564 35.5306 5.90625 37.6942 5.90625C39.8577 5.90625 41.829 6.39564 43.608 7.37317ZM34.0425 10.6341C32.8977 11.2895 31.9925 12.1847 31.3271 13.3182C30.6605 14.4518 30.3284 15.7053 30.3284 17.0785C30.3284 18.4518 30.6667 19.7102 31.3421 20.8551C32.0175 21.9999 32.9226 22.905 34.0575 23.5704C35.191 24.2371 36.4232 24.5692 37.7553 24.5692C39.0662 24.5692 40.2834 24.2371 41.407 23.5704C42.5306 22.905 43.4195 21.9999 44.0749 20.8551C44.7304 19.7115 45.0575 18.4518 45.0575 17.0785C45.0575 15.7053 44.7304 14.4518 44.0749 13.3182C43.4195 12.1847 42.5306 11.2895 41.407 10.6341C40.2834 9.97866 39.0662 9.65157 37.7553 9.65157C36.4245 9.65032 35.186 9.97866 34.0425 10.6341Z'
        fill='#121C25'
      />
      <path
        d='M67.4963 6.1875H71.5537V28.0327H67.5899L56.3552 12.9915V28.0327H52.2666V6.1875H56.2304L67.4963 21.2599V6.1875Z'
        fill='#121C25'
      />
      <path
        d='M89.2641 10.398C88.2129 9.8986 87.1467 9.64891 86.0656 9.64891C84.7135 9.64891 83.4751 9.97226 82.3515 10.6165C81.2279 11.2619 80.344 12.1508 79.6985 13.2844C79.0531 14.418 78.731 15.6614 78.731 17.0135C78.731 18.3655 79.0531 19.609 79.6985 20.7425C80.3427 21.8761 81.2279 22.7713 82.3515 23.4267C83.4751 24.0821 84.7123 24.4092 86.0656 24.4092C87.1055 24.4092 88.1517 24.1808 89.2016 23.7226C90.2516 23.2656 91.1829 22.6302 91.9944 21.8187L94.4601 24.5653C93.3153 25.7301 91.9844 26.6514 90.4651 27.3268C88.9457 28.0035 87.4064 28.3406 85.8458 28.3406C83.7447 28.3406 81.8196 27.8412 80.0731 26.8424C78.3252 25.8437 76.952 24.4866 75.9532 22.77C74.9545 21.0534 74.4551 19.1558 74.4551 17.0746C74.4551 14.9947 74.9644 13.1008 75.9844 11.3955C77.0031 9.69011 78.3927 8.34804 80.1505 7.36926C81.9083 6.39173 83.8583 5.90234 86.0019 5.90234C87.5624 5.90234 89.0918 6.21445 90.5899 6.83867C92.088 7.46289 93.3677 8.32681 94.4289 9.42919L91.9944 12.4879C91.2241 11.5952 90.314 10.8974 89.2641 10.398Z'
        fill='#121C25'
      />
      <path
        d='M110.812 28.0314L107.035 21.572C106.868 21.5932 106.609 21.6032 106.255 21.6032H101.324V28.0314H97.1416V6.1875H106.254C109.083 6.1875 111.268 6.84293 112.807 8.15379C114.346 9.46465 115.117 11.3161 115.117 13.7081C115.117 15.4147 114.747 16.8704 114.009 18.0776C113.27 19.2849 112.194 20.19 110.78 20.793L115.586 28.0327H110.812V28.0314ZM101.325 17.9203H106.256C107.837 17.9203 109.044 17.577 109.877 16.8904C110.708 16.2037 111.125 15.185 111.125 13.8317C111.125 12.5009 110.71 11.5021 109.877 10.8354C109.044 10.17 107.838 9.83669 106.256 9.83669H101.325V17.9203Z'
        fill='#121C25'
      />
      <path
        d='M118.957 6.1875H134.966V9.83919H123.138V15.2374H133.749V18.8891H123.138V24.381H135.34V28.0327H118.957V6.1875Z'
        fill='#121C25'
      />
      <path
        d='M136.401 6.1875H154.22V9.90161H147.418V28.0327H143.204V9.90036H136.401V6.1875Z'
        fill='#121C25'
      />
      <path
        d='M171.025 7.37317C172.804 8.35069 174.202 9.69277 175.222 11.3994C176.241 13.106 176.752 14.9986 176.752 17.0785C176.752 19.1597 176.242 21.0573 175.222 22.7739C174.202 24.4905 172.804 25.8476 171.025 26.8463C169.246 27.8451 167.275 28.3445 165.111 28.3445C162.948 28.3445 160.976 27.8451 159.197 26.8463C157.418 25.8476 156.019 24.4905 155 22.7739C153.98 21.0573 153.471 19.1597 153.471 17.0785C153.471 14.9986 153.98 13.1048 155 11.3994C156.019 9.69402 157.418 8.35194 159.197 7.37317C160.976 6.39564 162.948 5.90625 165.111 5.90625C167.275 5.90625 169.246 6.39564 171.025 7.37317ZM161.459 10.6341C160.315 11.2895 159.41 12.1847 158.744 13.3182C158.079 14.4518 157.745 15.7053 157.745 17.0785C157.745 18.4518 158.084 19.7102 158.759 20.8551C159.434 21.9999 160.34 22.905 161.474 23.5704C162.608 24.2371 163.84 24.5692 165.172 24.5692C166.483 24.5692 167.7 24.2371 168.824 23.5704C169.948 22.905 170.836 21.9999 171.492 20.8551C172.147 19.7115 172.474 18.4518 172.474 17.0785C172.474 15.7053 172.147 14.4518 171.492 13.3182C170.836 12.1847 169.948 11.2895 168.824 10.6341C167.7 9.97866 166.483 9.65157 165.172 9.65157C163.841 9.65032 162.604 9.97866 161.459 10.6341Z'
        fill='#121C25'
      />
      <path
        d='M45.4241 33.2188H48.7724L48.7649 33.858H46.1282V35.5471H48.5003V36.1863H46.1282V38.3598H45.4229V33.2188H45.4241Z'
        fill='#898584'
      />
      <path
        d='M50.6996 37.3711C50.9618 37.6382 51.3226 37.7718 51.7833 37.7718C52.239 37.7718 52.596 37.6382 52.8557 37.3711C53.1154 37.1039 53.2452 36.7331 53.2452 36.2587V33.2188H53.9506V36.2587C53.9506 36.9291 53.7583 37.456 53.3738 37.838C52.9893 38.22 52.4599 38.411 51.7845 38.411C51.1041 38.411 50.5698 38.22 50.184 37.838C49.797 37.456 49.6035 36.9304 49.6035 36.2587V33.2188H50.3089V36.2587C50.3064 36.7344 50.4375 37.1051 50.6996 37.3711Z'
        fill='#898584'
      />
      <path
        d='M59.0818 33.2188H59.7796V38.3586H59.0668L56.1292 34.3786V38.3586H55.4238V33.2188H56.1367L59.0818 37.2063V33.2188Z'
        fill='#898584'
      />
      <path
        d='M64.8212 33.5521C65.2257 33.7756 65.5416 34.0827 65.7726 34.4734C66.0023 34.8655 66.1171 35.3037 66.1171 35.7881C66.1171 36.2725 66.001 36.7106 65.7688 37.1027C65.5366 37.4947 65.217 37.8018 64.81 38.024C64.403 38.2475 63.9486 38.3586 63.4442 38.3586H61.373V33.2188H63.4654C63.9661 33.2175 64.418 33.3286 64.8212 33.5521ZM64.4542 37.4697C64.7451 37.3037 64.976 37.0727 65.1446 36.7793C65.3131 36.4859 65.398 36.1576 65.398 35.7955C65.398 35.4285 65.3119 35.0977 65.1408 34.8043C64.9698 34.5109 64.7351 34.2787 64.4392 34.1101C64.1433 33.9416 63.8137 33.8567 63.4517 33.8567H62.0784V37.7194H63.4817C63.8387 37.7194 64.1633 37.6357 64.4542 37.4697Z'
        fill='#898584'
      />
      <path
        d='M70.6887 33.5204C71.1019 33.7501 71.429 34.0647 71.6687 34.4642C71.9084 34.8637 72.0282 35.3031 72.0282 35.7825C72.0282 36.2619 71.9084 36.7039 71.6687 37.1084C71.429 37.5129 71.1019 37.8312 70.6887 38.0634C70.2754 38.2956 69.821 38.4117 69.3266 38.4117C68.8322 38.4117 68.3778 38.2956 67.9646 38.0634C67.5513 37.8312 67.2243 37.5129 66.9846 37.1084C66.7449 36.7039 66.625 36.2632 66.625 35.7825C66.625 35.3031 66.7449 34.8637 66.9846 34.4642C67.2243 34.0647 67.5501 33.7513 67.9608 33.5204C68.3716 33.2906 68.8272 33.1758 69.3266 33.1758C69.821 33.1758 70.2754 33.2906 70.6887 33.5204ZM68.3391 34.0709C68.0332 34.2469 67.7898 34.4854 67.6088 34.7863C67.4277 35.0871 67.3366 35.4192 67.3366 35.7813C67.3366 36.1433 67.4277 36.4767 67.6088 36.78C67.7898 37.0834 68.0332 37.3243 68.3391 37.5029C68.645 37.6814 68.9771 37.7713 69.3341 37.7713C69.6912 37.7713 70.0208 37.6827 70.3216 37.5029C70.6225 37.3231 70.8622 37.0834 71.0407 36.78C71.2193 36.4767 71.3091 36.1433 71.3091 35.7813C71.3091 35.4192 71.2193 35.0871 71.0407 34.7863C70.8622 34.4854 70.6225 34.2469 70.3216 34.0709C70.0208 33.8949 69.6912 33.8062 69.3341 33.8062C68.9758 33.8062 68.645 33.8949 68.3391 34.0709Z'
        fill='#898584'
      />
      <path
        d='M78.6484 33.5521C79.0529 33.7756 79.3687 34.0827 79.5997 34.4734C79.8294 34.8655 79.9443 35.3037 79.9443 35.7881C79.9443 36.2725 79.8282 36.7106 79.596 37.1027C79.3637 37.4947 79.0441 37.8018 78.6372 38.024C78.2302 38.2475 77.7757 38.3586 77.2714 38.3586H75.2002V33.2188H77.2926C77.7932 33.2175 78.2451 33.3286 78.6484 33.5521ZM78.2813 37.4697C78.5722 37.3037 78.8032 37.0727 78.9717 36.7793C79.1403 36.4859 79.2252 36.1576 79.2252 35.7955C79.2252 35.4285 79.139 35.0977 78.968 34.8043C78.7969 34.5109 78.5622 34.2787 78.2664 34.1101C77.9705 33.9416 77.6409 33.8567 77.2789 33.8567H75.9056V37.7194H77.3088C77.6659 37.7194 77.9905 37.6357 78.2813 37.4697Z'
        fill='#898584'
      />
      <path
        d='M81.0684 33.2188H84.6377V33.858H81.7737V35.4447H84.3368V36.0839H81.7737V37.7219H84.7263V38.3611H81.0696V33.2188H81.0684Z'
        fill='#898584'
      />
      <path d='M88.0234 33.2188H88.7288V38.3586H88.0234V33.2188Z' fill='#898584' />
      <path
        d='M93.9704 33.2188H94.6683V38.3586H93.9555L91.0179 34.3786V38.3586H90.3125V33.2188H91.0254L93.9704 37.2063V33.2188Z'
        fill='#898584'
      />
      <path
        d='M95.5654 33.2188H96.322L98.0186 37.5733L99.7152 33.2188H100.457L98.3707 38.3586H97.6366L95.5654 33.2188Z'
        fill='#898584'
      />
      <path
        d='M101.344 33.2188H104.913V33.858H102.049V35.4447H104.612V36.0839H102.049V37.7219H105.002V38.3611H101.345V33.2188H101.344Z'
        fill='#898584'
      />
      <path
        d='M108.516 33.9479C108.258 33.8718 108.023 33.8343 107.807 33.8343C107.508 33.8343 107.27 33.8917 107.09 34.0066C106.911 34.1215 106.822 34.28 106.822 34.4798C106.822 34.6608 106.875 34.8093 106.983 34.9242C107.09 35.0391 107.222 35.1302 107.38 35.1964C107.536 35.2625 107.754 35.3399 108.034 35.4273C108.381 35.5347 108.663 35.6383 108.878 35.7357C109.092 35.8331 109.277 35.9791 109.428 36.1726C109.579 36.3661 109.655 36.6221 109.655 36.9404C109.655 37.2338 109.576 37.491 109.417 37.712C109.257 37.9317 109.035 38.1027 108.749 38.2226C108.463 38.3424 108.134 38.4024 107.762 38.4024C107.385 38.4024 107.018 38.3287 106.66 38.1826C106.303 38.0353 105.995 37.8406 105.735 37.5946L106.051 36.9854C106.296 37.2251 106.572 37.4098 106.878 37.5397C107.184 37.6695 107.481 37.7344 107.77 37.7344C108.114 37.7344 108.383 37.667 108.582 37.5322C108.78 37.3974 108.879 37.2151 108.879 36.9854C108.879 36.8044 108.825 36.6545 108.718 36.5372C108.611 36.4198 108.474 36.3262 108.311 36.2575C108.147 36.1889 107.925 36.1127 107.647 36.0303C107.303 35.9279 107.025 35.8268 106.809 35.7294C106.593 35.6321 106.411 35.4872 106.262 35.2962C106.114 35.1052 106.039 34.853 106.039 34.5397C106.039 34.265 106.115 34.0228 106.266 33.8131C106.417 33.6021 106.63 33.4411 106.905 33.3287C107.18 33.2163 107.497 33.1602 107.86 33.1602C108.169 33.1602 108.471 33.2051 108.767 33.2962C109.062 33.3874 109.326 33.5085 109.556 33.6595L109.262 34.2912C109.021 34.1377 108.773 34.0241 108.516 33.9479Z'
        fill='#898584'
      />
      <path
        d='M110.237 33.2188H114.255V33.8654H112.596V38.3598H111.89V33.8654H110.237V33.2188Z'
        fill='#898584'
      />
      <path d='M115.18 33.2188H115.885V38.3586H115.18V33.2188Z' fill='#898584' />
      <path
        d='M117.471 33.2188H118.308L120.189 36.8317L122.054 33.2188H122.898V38.3586H122.237L122.23 34.2537L120.401 37.8155H119.968L118.125 34.2537V38.3586H117.471V33.2188Z'
        fill='#898584'
      />
      <path
        d='M124.482 33.2188H128.052V33.858H125.188V35.4447H127.751V36.0839H125.188V37.7219H128.14V38.3611H124.484V33.2188H124.482Z'
        fill='#898584'
      />
      <path
        d='M133.053 33.2188H133.751V38.3586H133.038L130.101 34.3786V38.3586H129.396V33.2188H130.108L133.053 37.2063V33.2188Z'
        fill='#898584'
      />
      <path
        d='M134.684 33.2188H138.701V33.8654H137.042V38.3598H136.337V33.8654H134.684V33.2188Z'
        fill='#898584'
      />
      <path
        d='M142.912 33.5204C143.326 33.7501 143.653 34.0647 143.892 34.4642C144.132 34.8637 144.252 35.3031 144.252 35.7825C144.252 36.2619 144.132 36.7039 143.892 37.1084C143.653 37.5129 143.326 37.8312 142.912 38.0634C142.499 38.2956 142.045 38.4117 141.55 38.4117C141.056 38.4117 140.601 38.2956 140.188 38.0634C139.775 37.8312 139.448 37.5129 139.208 37.1084C138.968 36.7039 138.849 36.2632 138.849 35.7825C138.849 35.3031 138.968 34.8637 139.208 34.4642C139.448 34.0647 139.774 33.7513 140.184 33.5204C140.595 33.2906 141.051 33.1758 141.55 33.1758C142.043 33.1758 142.498 33.2906 142.912 33.5204ZM140.561 34.0709C140.256 34.2469 140.012 34.4854 139.831 34.7863C139.65 35.0871 139.559 35.4192 139.559 35.7813C139.559 36.1433 139.649 36.4767 139.831 36.78C140.012 37.0834 140.256 37.3243 140.561 37.5029C140.867 37.6814 141.199 37.7713 141.556 37.7713C141.914 37.7713 142.243 37.6827 142.544 37.5029C142.845 37.3243 143.085 37.0834 143.263 36.78C143.442 36.4767 143.532 36.1433 143.532 35.7813C143.532 35.4192 143.442 35.0871 143.263 34.7863C143.085 34.4854 142.845 34.2469 142.544 34.0709C142.243 33.8949 141.914 33.8062 141.556 33.8062C141.199 33.8062 140.867 33.8949 140.561 34.0709Z'
        fill='#898584'
      />
      <path d='M191.163 17.6211H185.814V22.9694H191.163V17.6211Z' fill='#898584' />
      <path d='M196.51 12.2734H191.162V17.6218H196.51V12.2734Z' fill='#EBD8B9' />
      <path d='M185.815 22.9688H180.467V28.3171H185.815V22.9688Z' fill='#121C25' />
    </svg>
  )
}

export default LogoNovoRumo
