export { default as LogoFull } from './LogoFull'
export { default as LogoFullWhite } from './LogoWhite'
export { default as LogoAlfa } from './LogoAlfa'
export { default as LogoAlfaMin } from './LogoAlfaMin'
export { default as LogoRodobens } from './LogoRodobens'
export { default as LogoRodobensMin } from './LogoRodobens/LogoMin'
export { default as LogoArbi } from './LogoArbi'
export { default as LogoRb } from './LogoRb'
export { default as LogoMin } from './LogoMin'
export { default as LogoPefisa } from './LogoPefisa'
export { default as LogoNovoRumo } from './LogoNovoRumo'
export { default as LogoMeuCashCard } from './LogoMeuCashCard'
export { default as LogoAegro } from './LogoAegro'
export { default as LogoAfinz } from './LogoAfinz'
export { default as LogoSerEducacional } from './LogoSerEducacional'
export { default as LogoBamaq } from './LogoBamaq'
export { default as LogoWoman } from './WomanDashboard'
export { default as LogoCondor } from './LogoCondor'
export { default as LogoTorra } from './LogoTorra'
export { default as LogoCora } from './LogoCora'
export { default as LogoBMS } from './LogoBMS'
export { default as LogoEstBank } from './LogoEstBank'
export * from './Feedbacks'
export { default as OnidataMail } from './OnidataMail'
export { default as CheckCircle } from './CheckCircle'
export { default as HireCredit } from './HireCredit'
export * from './ProofOfIncome'
export * from './ProofOfAddress'
export * from './IdentityCardBack'
export * from './IdentityCardFront'
