import { IRouteCodes } from 'interfaces'
import LazyLoading from 'components/LazyLoading'
import {
  Receipt,
  AccountBalanceWalletOutlined,
  PersonOutlineOutlined,
  AttachMoneyOutlined,
  RestorePageOutlined,
  AttachMoney,
  DriveEta,
  MoneyOff,
  Description,
} from '@material-ui/icons'
import { routeCodes, subRouteCodes } from 'constants/routes'
import hasSubmodulePermission from 'permissions/hasSubmodulePermission'
import { SALESCHANNEL_SUBMODULES } from 'interfaces/submodules'

const Location = LazyLoading(() => import('../Default/pages/Location'))
const ProposalLayout = LazyLoading(() => import('./pages/Proposal'))
const ProposalList = LazyLoading(() => import('./pages/Proposal/List'))
const ProposalDetails = LazyLoading(() => import('./pages/Proposal/Resume'))
const ContractLayout = LazyLoading(() => import('./pages/Contracts'))
const ContractList = LazyLoading(() => import('./pages/Contracts/List'))
const ContractView = LazyLoading(() => import('./pages/Contracts/View'))
const CanceledCaixa = LazyLoading(() => import('./pages/CanceledBooking'))
const SimulationFGTSLayout = LazyLoading(() => import('./pages/SimulationFGTS'))
const SimulationFormData = LazyLoading(() => import('./pages/SimulationFGTS/FormData'))
const SimulationView = LazyLoading(() => import('./pages/SimulationFGTS/View'))
const RequestFGTSLayout = LazyLoading(() => import('./pages/RequestFGTS'))
const RequestFormData = LazyLoading(() => import('./pages/RequestFGTS/FormData'))
const RequestView = LazyLoading(() => import('./pages/RequestFGTS/View'))
const RequestFGTSRegister = LazyLoading(() => import('./pages/RequestFGTS/Register'))
const RequestVehiclesLayout = LazyLoading(() => import('./pages/Vehicles'))
const RequestClientFormData = LazyLoading(() => import('./pages/Vehicles/ClientIdentifier'))
const RequestVehiclesFormData = LazyLoading(() => import('./pages/Vehicles/FormDataVehicle'))
const RequestVehiclesView = LazyLoading(() => import('./pages/Vehicles/Simulation'))
const RequestVehiclesRegister = LazyLoading(() => import('./pages/Vehicles/Register'))
const ResumoVechicle = LazyLoading(() => import('./pages/Vehicles/Resume'))
const OperatorsLayout = LazyLoading(() => import('./pages/Operators'))
const OperatorsList = LazyLoading(() => import('./pages/Operators/List'))
const OperatorsCreate = LazyLoading(() => import('./pages/Operators/New'))
const OperatorsView = LazyLoading(() => import('./pages/Operators/View'))
const Redirect = LazyLoading(() => import('components/Redirect404'))
const MyAccount = LazyLoading(() => import('app/Company/pages/MyAccount'))
const MyAccountList = LazyLoading(() => import('app/Company/pages/MyAccount/List'))
const MyAccountChangeMail = LazyLoading(() => import('app/Company/pages/MyAccount/ChangeMail'))
const MyAccountChangePassword = LazyLoading(() =>
  import('app/Company/pages/MyAccount/ChangePassword')
)
const HireCredit = LazyLoading(() => import('./pages/HireCredit'))
const Simulate = LazyLoading(() => import('./pages/HireCredit/Simulate'))
const VerifyIdentity = LazyLoading(() => import('./pages/HireCredit/VerifyIdentity'))
const Register = LazyLoading(() => import('./pages/HireCredit/Register'))
const Resume = LazyLoading(() => import('./pages/HireCredit/Resume'))
const Renegotiate = LazyLoading(() => import('./pages/Renegotiate'))
const RenegotiateVerify = LazyLoading(() => import('./pages/Renegotiate/VerifyIdentity'))
const RenegotiateList = LazyLoading(() => import('./pages/Renegotiate/List'))
const RenegotiateSimulate = LazyLoading(() => import('./pages/Renegotiate/Simulate'))
const RenegotiateConfirmData = LazyLoading(() => import('./pages/Renegotiate/ConfirmData'))
const RenegotiateResume = LazyLoading(() => import('./pages/Renegotiate/Resume'))
const MyAccountChangePhone = LazyLoading(() => import('app/Company/pages/MyAccount/ChangePhone'))
const MyAccountToken = LazyLoading(() => import('app/Company/pages/MyAccount/Token'))

// Renegociação CDV PJ/PF

const RenegotiateCDV = LazyLoading(() => import('./pages/Vehicles/Renegotiate'))
const RenegotiateVerifyCDV = LazyLoading(() =>
  import('./pages/Vehicles/Renegotiate/ClientIdentifier')
)
const RenegotiateListCDV = LazyLoading(() => import('./pages/Vehicles/Renegotiate/List'))
const RenegotiateSimulateCDV = LazyLoading(() => import('./pages/Vehicles/Renegotiate/Simulate'))
const RenegotiateConfirmDataCDV = LazyLoading(() =>
  import('./pages/Vehicles/Renegotiate/ConfirmData')
)
const RenegotiateResumeCDV = LazyLoading(() => import('./pages/Vehicles/Renegotiate/Resume'))

export const DefaultRoutes: IRouteCodes = {
  DEFAULT: {
    path: routeCodes.NULL_ROUTE,
    name: 'Página não encontrada',
    component: Redirect,
    hideInSidePanel: true,
  },
  HIRE_CREDIT: {
    path: routeCodes.SALES_CHANNEL.HIRE_CREDIT,
    name: 'Solicitar Crédito',
    icon: AttachMoney,
    component: HireCredit,
    defaultRoute: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Identificação',
        component: VerifyIdentity,
      },
      SIMULATE: {
        path: subRouteCodes.SALES_CHANNEL.HIRE_CREDIT.SIMULATE,
        name: 'Simulação de crédito',
        component: Simulate,
      },
      VERIFY_IDENTITY: {
        path: subRouteCodes.SALES_CHANNEL.HIRE_CREDIT.VERIFY_IDENTITY,
        name: 'Identificar',
        component: VerifyIdentity,
      },
      REGISTER: {
        path: subRouteCodes.SALES_CHANNEL.HIRE_CREDIT.REGISTER,
        name: 'Registrar cliente',
        component: Register,
      },
      RESUME: {
        path: subRouteCodes.SALES_CHANNEL.HIRE_CREDIT.RESUME,
        name: 'Resumo',
        component: Resume,
      },
    },
  },
  CDC_VEHICLES: {
    path: routeCodes.SALES_CHANNEL.CDC_VEHICLES,
    name: 'Solicitar crédito',
    icon: DriveEta,
    component: RequestVehiclesLayout,
    permissions: [hasSubmodulePermission(SALESCHANNEL_SUBMODULES.CDV)],
    defaultRoute: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'CDC Veículos',
        component: RequestClientFormData,
      },
      VIEW: {
        path: subRouteCodes.VIEW,
        name: 'CDC Veículos',
        component: RequestVehiclesView,
      },
      FORM_DATA_VEHICLES: {
        path: subRouteCodes.SALES_CHANNEL.CDC_VEHICLES.FORM_DATA_VEHICLES,
        name: 'CDC Veículos',
        component: RequestVehiclesFormData,
      },
      FORM_DATA: {
        path: subRouteCodes.SALES_CHANNEL.CDC_VEHICLES.FORM_DATA_CLIENT,
        name: 'CDC Veículos',
        component: RequestVehiclesRegister,
      },
      RESUME: {
        path: subRouteCodes.SALES_CHANNEL.CDC_VEHICLES.RESUME,
        name: 'CDC Veículos',
        component: ResumoVechicle,
      },
    },
  },
  RENEGOTIATE_CDV: {
    path: routeCodes.SALES_CHANNEL.RENEGOTIATE_CDV,
    name: 'Renegociar Crédito',
    icon: RestorePageOutlined,
    permissions: [hasSubmodulePermission(SALESCHANNEL_SUBMODULES.CDV)],
    defaultRoute: true,
    component: RenegotiateCDV,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Renegociar Crédito',
        component: RenegotiateVerifyCDV,
      },
      LIST: {
        path: subRouteCodes.LIST,
        name: 'Renegociar Crédito',
        component: RenegotiateListCDV,
      },
      SIMULATE: {
        path: subRouteCodes.SALES_CHANNEL.RENEGOTIATE_CDV.SIMULATE,
        name: 'Renegociar Crédito',
        component: RenegotiateSimulateCDV,
      },
      CONFIRM_DATA: {
        path: subRouteCodes.SALES_CHANNEL.RENEGOTIATE_CDV.CONFIRM_DATA,
        name: 'Atualizar dados',
        component: RenegotiateConfirmDataCDV,
      },
      RESUME: {
        path: subRouteCodes.SALES_CHANNEL.RENEGOTIATE_CDV.RESUME,
        name: 'Resumo',
        component: RenegotiateResumeCDV,
      },
    },
  },
  RENEGOTIATE: {
    path: routeCodes.SALES_CHANNEL.RENEGOTIATE,
    name: 'Renegociar Crédito',
    icon: RestorePageOutlined,
    component: Renegotiate,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Renegociar Crédito',
        component: RenegotiateVerify,
      },
      LIST: {
        path: subRouteCodes.LIST,
        name: 'Renegociar Crédito',
        component: RenegotiateList,
      },
      SIMULATE: {
        path: subRouteCodes.SALES_CHANNEL.RENEGOTIATE.SIMULATE,
        name: 'Renegociar Crédito',
        component: RenegotiateSimulate,
      },
      CONFIRM_DATA: {
        path: subRouteCodes.SALES_CHANNEL.RENEGOTIATE.CONFIRM_DATA,
        name: 'Atualizar dados',
        component: RenegotiateConfirmData,
      },
      RESUME: {
        path: subRouteCodes.SALES_CHANNEL.RENEGOTIATE.RESUME,
        name: 'Resumo',
        component: RenegotiateResume,
      },
    },
  },
  LOCATION: {
    path: routeCodes.SALES_CHANNEL.LOCATION,
    name: 'Habilitar localização',
    component: Location,
  },
  FGTS_SIMULATION: {
    path: routeCodes.SALES_CHANNEL.FGTS_SIMULATION,
    name: 'Simular crédito com FGTS',
    icon: AccountBalanceWalletOutlined,
    component: SimulationFGTSLayout,
    permissions: [hasSubmodulePermission(SALESCHANNEL_SUBMODULES.CP)],
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Simular crédito com FGTS',
        component: SimulationFormData,
        componentProps: {
          redirectPath: `${routeCodes.ENTITY.SALES_CHANNEL}${routeCodes.SALES_CHANNEL.FGTS_SIMULATION}`,
        },
      },
      VIEW: {
        path: subRouteCodes.VIEW,
        name: 'Simular crédito com FGTS',
        component: SimulationView,
      },
    },
  },
  FGTS_REQUEST: {
    path: routeCodes.SALES_CHANNEL.FGTS_REQUEST,
    name: 'Solicitar crédito com FGTS',
    icon: AttachMoneyOutlined,
    component: RequestFGTSLayout,
    permissions: [hasSubmodulePermission(SALESCHANNEL_SUBMODULES.CP)],
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Solicitar crédito com FGTS',
        component: RequestFormData,
      },
      VIEW: {
        path: subRouteCodes.VIEW,
        name: 'Solicitar crédito com FGTS',
        component: RequestView,
      },
      FORM_DATA: {
        path: subRouteCodes.SALES_CHANNEL.FGTS_REQUEST.FORM_DATA_CLIENT,
        name: 'Solicitar crédito com FGTS',
        component: RequestFGTSRegister,
      },
    },
  },
  PROPOSALS: {
    path: routeCodes.SALES_CHANNEL.PROPOSAL,
    name: 'Propostas',
    icon: Description,
    component: ProposalLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Aprovadas',
        component: ProposalList,
      },
      VIEW: {
        path: '/:id',
        name: 'Detalhes da proposta',
        component: ProposalDetails,
        hideInSidePanel: true,
      },
      CREDIT_TABLE: {
        path: subRouteCodes.SALES_CHANNEL.PROPOSALS.CREDIT_TABLE,
        name: 'Mesa de crédito',
        component: ProposalList,
      },
      CANCELED: {
        path: subRouteCodes.SALES_CHANNEL.PROPOSALS.CANCELATED,
        name: 'Canceladas',
        component: ProposalList,
      },
      DENIED: {
        path: subRouteCodes.SALES_CHANNEL.PROPOSALS.DENIED,
        name: 'Negadas',
        component: ProposalList,
      },
      PENDING: {
        path: subRouteCodes.SALES_CHANNEL.PROPOSALS.PENDING_DOC,
        name: 'Pendentes de documentação',
        component: ProposalList,
      },
    },
  },
  CONTRACTS: {
    path: routeCodes.SALES_CHANNEL.CONTRACTS,
    name: 'Contratos',
    icon: Receipt,
    component: ContractLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Aprovadas',
        component: ContractList,
      },
      VIEW: {
        path: '/:id',
        name: 'Detalhes da proposta',
        component: ContractView,
        hideInSidePanel: true,
      },
      CANCELATED: {
        path: subRouteCodes.SALES_CHANNEL.CONTRACTS.CANCELATED,
        name: 'Cancelados',
        component: ContractList,
      },
      REVERSED: {
        path: subRouteCodes.SALES_CHANNEL.CONTRACTS.REVERSED,
        name: 'Estornados',
        component: ContractList,
      },
      EXPIRATED: {
        path: subRouteCodes.SALES_CHANNEL.CONTRACTS.EXPIRATED,
        name: 'Expirados',
        component: ContractList,
      },
      PENDING_SIGN: {
        path: subRouteCodes.SALES_CHANNEL.CONTRACTS.PENDING_SIGN,
        name: 'Pendente de assinatura',
        component: ContractList,
      },
      PENDING_TED: {
        path: subRouteCodes.SALES_CHANNEL.CONTRACTS.PENDING_TED,
        name: 'Pendente de TED',
        component: ContractList,
      },
    },
  },
  MY_ACCOUNT: {
    path: routeCodes.COMPANY.MY_ACCOUNT,
    name: 'Minha Conta',
    component: MyAccount,
    hideInSidePanel: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Minha Conta',
        component: MyAccountList,
      },
      CHANGE_MAIL: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_MAIL,
        name: 'Alterar email',
        component: MyAccountChangeMail,
      },
      CHANGE_PASSWORD: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_PASSWORD,
        name: 'Alterar Senha',
        component: MyAccountChangePassword,
      },
      CHANGE_TELEPHONE: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.CHANGE_PHONE,
        name: 'Alterar Telefone',
        component: MyAccountChangePhone,
      },
      TOKEN: {
        path: subRouteCodes.COMPANY.MY_ACCOUNT.TOKEN,
        name: 'Confirmar código',
        component: MyAccountToken,
      },
    },
  },
  OPERATORS: {
    path: routeCodes.SALES_CHANNEL.OPERATORS,
    name: 'Operadores',
    component: OperatorsLayout,
    icon: PersonOutlineOutlined,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Operadores',
        component: OperatorsList,
      },
      VIEW: {
        path: '/:id',
        name: 'Visualizar operador',
        component: OperatorsView,
      },
      EDIT: {
        path: `${subRouteCodes.EDIT}/:id`,
        name: 'Editar Operador',
        component: OperatorsCreate,
      },
      NEW: {
        path: subRouteCodes.NEW,
        name: 'Novo Operador',
        component: OperatorsCreate,
      },
    },
  },
  CANCELED_CAIXA: {
    path: routeCodes.SALES_CHANNEL.CANCELED_CAIXA,
    name: 'Cancelar proposta/contrato FGTS',
    component: CanceledCaixa,
    icon: MoneyOff,
    permissions: [hasSubmodulePermission(SALESCHANNEL_SUBMODULES.CP)],
  },
}

export const specificRoutes: { [key: string]: IRouteCodes } = {
  CDCVEICULO: {
    DEFAULT: DefaultRoutes.DEFAULT,
    CDC_VEHICLES: DefaultRoutes.CDC_VEHICLES,
    RENEGOTIATE: DefaultRoutes.RENEGOTIATE_CDV,
    PROPOSAL: DefaultRoutes.PROPOSALS,
    CONTRACTS: DefaultRoutes.CONTRACTS,
    OPERATORS: DefaultRoutes.OPERATORS,
    LOCATION: DefaultRoutes.LOCATION,
    MY_ACCOUNT: DefaultRoutes.MY_ACCOUNT,
  },
  FGTS_ALFA: {
    HIRE_CREDIT: DefaultRoutes.HIRE_CREDIT,
    FGTS_SIMULATION: DefaultRoutes.FGTS_SIMULATION,
    FGTS_REQUEST: DefaultRoutes.FGTS_REQUEST,
    PROPOSAL: DefaultRoutes.PROPOSALS,
    CONTRACTS: DefaultRoutes.CONTRACTS,
    OPERATORS: DefaultRoutes.OPERATORS,
    CANCELED_CAIXA: DefaultRoutes.CANCELED_CAIXA,
    LOCATION: DefaultRoutes.LOCATION,
    MY_ACCOUNT: DefaultRoutes.MY_ACCOUNT,
  },
  FGTS_PEFISA: {
    HIRE_CREDIT: DefaultRoutes.HIRE_CREDIT,
    FGTS_SIMULATION: DefaultRoutes.FGTS_SIMULATION,
    FGTS_REQUEST: DefaultRoutes.FGTS_REQUEST,
    PROPOSAL: DefaultRoutes.PROPOSALS,
    CONTRACTS: DefaultRoutes.CONTRACTS,
    OPERATORS: DefaultRoutes.OPERATORS,
    CANCELED_CAIXA: DefaultRoutes.CANCELED_CAIXA,
    LOCATION: DefaultRoutes.LOCATION,
    MY_ACCOUNT: DefaultRoutes.MY_ACCOUNT,
  },
}

export default DefaultRoutes
